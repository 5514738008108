body {
  margin: 0;
  padding: 0;
  color: #f1f0f7;
  background-color: #1d222e;
  touch-action: pan-x pan-y;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media print {
    visibility: hidden;
  }
}
.onfido-sdk-ui-Theme-modalOverlay {
  z-index: 99999 !important;
  background-color: transparent !important;
}
.onfido-sdk-ui-Welcome-instructions > span {
  text-align: center;
  display: block;
}
.onfido-sdk-ui-Welcome-instructions > ol {
  text-align: left;
  padding: 0 !important;
}
.onfido-sdk-ui-NavigationBar-iconBack {
  outline: none !important;
}
button[data-onfido-qa='welcome-next-btn'] {
  font-size: 15px;
}
.active-zendeck {
  right: 340px !important;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

*:is([contenteditable='true'])
  :not([tabindex^='-'])
  :not(input)
  :focus-visible:not(textarea) {
  /* box-shadow: 0 0 0 1px #fff; */
  outline: 1px solid white;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

img,
svg {
  line-height: 1;
}
